import React from "react";
import Home from "./Pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import Reference from "./Pages/References";

function App() {
  return ( 
    <>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/ref" element={<Reference />} />     
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
