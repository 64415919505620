import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import Navbar from "../Elements/Navbar";
import VideoCam from "../media/videocam.png";
import { BsFillCakeFill } from "react-icons/bs";
import { BsFillCarFrontFill } from "react-icons/bs";
import { FcAdvertising } from "react-icons/fc";
import { MdOutlineSportsGymnastics } from "react-icons/md";
import Video from "./../media/video.mov";
import Video2 from "./../media/video2.mov";
import Video3 from "./../media/video3.mov";
import Video4 from "./../media/video4.mov";
import { motion } from "framer-motion";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import WebVideo from "./../media/webvideo.mov";
import WebVideoPhone from "./../media/webvideoallo.mov";
import Contact from "../Elements/Contact";

import "../style.css";
function Home() {
  const [aboutTitleIndex, setAboutTitleIndex] = useState(0);

  const listAbout = ["Esküvő", "Reklám ", "Autó ", "Sport "];
  useEffect(() => {
    var video = document.getElementById("headerVideo");
    video.play();
    document.querySelectorAll(".flipcard").forEach(function (flipcard) {
      flipcard.addEventListener("mouseenter", function () {
        var video = flipcard.querySelector(".background-video");
        video.play();
      });

      flipcard.addEventListener("mouseleave", function () {
        var video = flipcard.querySelector(".background-video");
        video.pause();
        video.currentTime = 0;
      });
    });
    const intervalId = setInterval(() => {
      if (aboutTitleIndex === listAbout.length - 1) {
        setAboutTitleIndex(0);
      } else {
        setAboutTitleIndex((prevIndex) => prevIndex + 1);
      }
    }, 1500);

    return () => clearInterval(intervalId);
  }, [aboutTitleIndex, listAbout.length]);
  const makekey = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  return (
    <>
      <Navbar />
      <div className="video">
        <video
          playsInline
          muted
          loop
          id="headerVideo"
          style={{
            width: window.innerWidth,
            height: window.innerHeight + "px",
          }}>
          <source
            src={window.innerWidth <= 1080 ? WebVideoPhone : WebVideo}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="container">
        <div className="header">
          <div className="row">
            <div className="col-md">
              <div className="headertext">
                <div className="headerInfo">
                  <br />
                  <br />
                  <br />
                  <h3 className="fs-3">
                    <span
                      style={{ color: "var(--mainColor)", fontWeight: "bold" }}>
                      {" "}
                      Üdvözöllek
                    </span>{" "}
                    a videózás kreatív világában!
                    <br />
                  </h3>

                  <motion.div
                    key={makekey(5)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{}}>
                    <p style={{ color: "var(--mainColor)" }} className="infoT">
                      {" "}
                      {listAbout[aboutTitleIndex]}
                    </p>
                  </motion.div>
                  <button className="custombtn">Kapcsolat</button>
                </div>
              </div>
            </div>
            <div className="col-md">
              {/* <img src={VideoCam} className="img-fluid landing" alt="" /> */}
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
      <div className="" id="about">
        <div className="rootCard bg-dark">
          <h3 className="text-center text-light ">Rólam</h3>
        </div>
        <br></br>
        <div className="d-flex justify-content-center mt-5 container">
          <img src={VideoCam} className="img-fluid landing mt-4" alt="" />
          <div className="aboutInfo">
            <p>
              Pál Vilmos vagyok a Will Movie megalkotója. Évek óta benne vagyok
              a videós szakmában és már megannyi munka van a hátam mögött.
              Szeretek mindenféle témában tevékenykedni és kreatív módon a
              legtöbbet kihozni belőle. Legyen szó a közelgő esküvődről, a
              különleges autódról vagy motorodról, vállalkozásod
              népszerűsítéséről, rendezvényed megörökítéséről,
              sportteljesítményed vagy akár ingatlanod bemutatásáról, rám
              számíthatsz. Munkámból próbálom mindig a lehető legtöbbet kihozni,
              hogy Te elégedett lehess és azt kapd a pénzedért, amit
              megálmodtál. A projektek mindig gördülékenyen zajlanak, hiszen a
              kommunikáció az erősségeim közé tartozik. Ha felkeltettem
              érdeklődésed, írj email-t vagy hívj telefonon.
            </p>
          </div>
        </div>
      </div>

      <div className="" id="services">
        <div className="rootCard bg-dark">
          <h3 className="text-center text-light ">Szolgáltatásaim</h3>
        </div>

        <div className="container-fluid mt-5 serviesAll color-red-300">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: false }}
            transition={{
              ease: "linear",
              duration: 0.3,
            }}>
            <div className="row g-5">
              <div className="col-xl-3 mb-5">
                <div class="flipcard" style={{ minWidth: "40vh" }}>
                  <div class="flipcard-inner">
                    <div class="flipcard-front">
                      <div className="">
                        <div className="servicesCard">
                          <div className="color color1">
                            <div className="d-flex justify-content-center icon">
                              <BsFillCakeFill
                                className="text-light"
                                size={"20%"}
                              />
                            </div>
                          </div>
                          <br />
                          <br />
                          <br />
                          <br />
                          <hr />
                          <p className="title">Esküvő</p>
                          <hr />
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                    <div class="flipcard-back">
                      <video playsInline muted loop class="background-video">
                        <source src={Video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
                <div
                  className="input-group  mb-2"
                  style={{ marginTop: "12vh", width: "44.3vh" }}>
                  <button
                    className="custombtn form-control"
                    style={{ borderRadius: "0" }}
                    onClick={() =>
                      (window.location.href =
                        "https://www.youtube.com/playlist?list=PL_FnKwVv05ADD2gVwYOX_VkmHknd7DlWd")
                    }>
                    További munkáim
                  </button>
                </div>
              </div>
              <div className="col-xl-3 mb-5">
                <div class="flipcard" style={{ minWidth: "40vh" }}>
                  <div class="flipcard-inner">
                    <div class="flipcard-front">
                      <div className="">
                        <div className="servicesCard">
                          <div className="color color2">
                            <div className="d-flex justify-content-center icon">
                              <FcAdvertising
                                className="text-light"
                                size={"20%"}
                              />
                            </div>
                          </div>
                          <br />
                          <br />
                          <br />
                          <br />
                          <hr />
                          <p className="title">reklám</p>
                          <hr />
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                    <div class="flipcard-back">
                      <video playsInline muted loop class="background-video">
                        <source src={Video3} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
                <div
                  className="input-group  mb-2"
                  style={{ marginTop: "12vh", width: "44.3vh" }}>
                  <button
                    className="custombtn form-control"
                    style={{ borderRadius: "0" }}
                    onClick={() =>
                      (window.location.href =
                        "https://www.youtube.com/playlist?list=PL_FnKwVv05ABQT9Qa7S10hE6XEqy1Gjpp")
                    }>
                    További munkáim
                  </button>
                </div>
              </div>
              <div className="col-xl-3 mb-5">
                <div class="flipcard" style={{ minWidth: "40vh" }}>
                  <div class="flipcard-inner">
                    <div class="flipcard-front">
                      <div className="">
                        <div className="servicesCard">
                          <div className="color color3">
                            <div className="d-flex justify-content-center icon">
                              <BsFillCarFrontFill
                                className="text-light"
                                size={"20%"}
                              />
                            </div>
                          </div>
                          <br />
                          <br />
                          <br />
                          <br />
                          <hr />
                          <p className="title">autó - motor</p>
                          <hr />
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                    <div class="flipcard-back">
                      <video playsInline muted loop class="background-video">
                        <source src={Video2} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
                <div
                  className="input-group  mb-2"
                  style={{ marginTop: "12vh", width: "44.3vh" }}>
                  <button
                    className="custombtn form-control"
                    style={{ borderRadius: "0" }}
                    onClick={() =>
                      (window.location.href =
                        "https://www.youtube.com/playlist?list=PL_FnKwVv05AD9pAvn6nQfn-nsu7biGvKW")
                    }>
                    További munkáim
                  </button>
                </div>
              </div>
              <div className="col-xl-3 mb-5">
                <div class="flipcard" style={{ minWidth: "40vh" }}>
                  <div class="flipcard-inner">
                    <div class="flipcard-front">
                      <div className="">
                        <div className="servicesCard">
                          <div className="color color4">
                            <div className="d-flex justify-content-center icon">
                              <MdOutlineSportsGymnastics
                                className="text-light"
                                size={"20%"}
                              />
                            </div>
                          </div>
                          <br />
                          <br />
                          <br />
                          <br />
                          <hr />
                          <p className="title">SPORT</p>
                          <hr />
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                    <div class="flipcard-back">
                      <video playsInline muted loop class="background-video">
                        <source src={Video4} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
                <div
                  className="input-group  mb-2"
                  style={{ marginTop: "12vh", width: "44.3vh" }}>
                  <button
                    className="custombtn form-control"
                    style={{ borderRadius: "0" }}
                    onClick={() =>
                      (window.location.href =
                        "https://youtube.com/playlist?list=PL_FnKwVv05AAG7vJx37DwOM20mrnrjhdc&si=tpyY3eNm2xX9T15w")
                    }>
                    További munkáim
                  </button>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
          </motion.div>
        </div>
        <div>
          <Contact />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

export default Home;
