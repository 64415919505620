

import { VscListSelection } from "react-icons/vsc";
import { useLocation, Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { IoMdClose } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import {motion} from 'framer-motion';
import Logo from './../media/logo.png';
import LogoTitle from './../media/title.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import './../style.css'
import { FaYoutube } from "react-icons/fa";



class NavLink {
    constructor(id, name, path) {
        this.id = id;
        this.name = name;
        this.path = path;
    }
}

function Navbar() {
    const [isOpen, setOpen] = useState(false);
    const loc = useLocation();
    const navRef = useRef();
    const navLinks = [new NavLink(0, "Kezdőlap", "#home"),
    new NavLink(1, "Rólam", "#about"),
    new NavLink(2, "Szolgáltatásaim", "#services"),
    new NavLink(3, "Kapcsolat", "#contact"),
    new NavLink(4, "Referencia", "ref"),
    ];
    
   
    useEffect(() => {
        navLinks.forEach(i => {
            if (i.path == loc.pathname) {
                document.title = "Will Movie | " + i.name;
            }
        });
        if(loc.hash == "#home"){
            document.documentElement.scrollTop = 0;
          
         }
       try{
        document.getElementById(loc.hash.split('').slice(1, loc.hash.split('').length).join('')).scrollIntoView();
       

       }catch(err){

       }
        
    },[loc.hash])
    const toggle = () => {
        setOpen(!isOpen);
    }
   
      
    return (
        <>
                    
            <nav class="navbar navbar-expand-lg sticky-top bg-dark" ref={navRef} style={{ zIndex: "99", transition: "all 0.6"}}>
                <div class="container">
                    <div className="d-flex justify-content-between">
                        <a class="navbar-brand " href="/">
                            <img src={Logo} alt="" className='navlogo' />
                            <img src={LogoTitle} alt="" className='navlogotext ' />
                            </a>
                        <button class="navbar-toggler" type="button" style={{border: "none"}} onClick={toggle} data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                            {!isOpen ? <VscListSelection className='text-light' size={30} /> : <IoMdClose className='text-light' size={33} />}

                        </button>
                    </div>
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo02" >
                        <ul class="navbar-nav mb-2 ms-auto mt-2">
                            {navLinks.map(i => (
                                <li class="nav-item">
                                    <Link class={`nav-link text-light ${loc.hash == i.path ||  loc.pathname == "/"+i.path ? " active" : ""}`} style={{ color: `${loc.pathname == i.path ? "" : "black"}` }} to={i.hash == null ? "/"+i.path : i.path+"/"}>{i.name}</Link>
                                </li>
                            ))}
                        </ul>
                        <div className="d-flex justify-content-center">
                            <ul className='navbar-nav mb-2 ms-5'>
                                <li class="nav-item">
                                    <a class={`nav-link text-light`} href={"https://facebook.willmovie.hu/"} target='_blank'>
                                        <FaFacebook className='text-primary' size={30} />
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class={`nav-link text-light`} href={"https://youtube.willmovie.hu/"} target='_blank'>
                                        <FaYoutube className='text-danger' size={35} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav >
           
           
        </>
    );
}
export default Navbar;