import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import Navbar from "../Elements/Navbar";
function Reference() {
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row mt-3">
          <div className="col-md mb-3">
            <p className="fs-4">Autó-Motor</p>
            <hr />
            <div className="d-flex justify-content-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/videoseries?si=WXQph82hWohEkPSv&amp;list=PL_FnKwVv05AD9pAvn6nQfn-nsu7biGvKW"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-md mb-3">
          <p className="fs-4">Reklám</p>
            <hr />
            <div className="d-flex justify-content-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/videoseries?si=z34cRPUm0aGU_f9O&amp;list=PL_FnKwVv05ABQT9Qa7S10hE6XEqy1Gjpp"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-md mb-3">
          <p className="fs-4">Esküvő</p>
            <hr />
            <div className="d-flex justify-content-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/videoseries?si=fc6kkBQoEraIDHCw&amp;list=PL_FnKwVv05ADD2gVwYOX_VkmHknd7DlWd"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-md mb-3">
          <p className="fs-4">Sport</p>
            <hr />
            <div className="d-flex justify-content-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/videoseries?si=UGT-16_-GnKjh6fW&amp;list=PL_FnKwVv05AAG7vJx37DwOM20mrnrjhdc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reference;
