import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "./../style.css";
import $ from 'jquery';
import { FaEnvelope } from "react-icons/fa";
import {motion} from "framer-motion";
import { CiPhone } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { RiMapPin2Line } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
function Contact() {
  const name = useRef();
  const email = useRef();
  const subject = useRef();
  const message = useRef();
  const sendBtn = useRef();
  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }
  useEffect(() => {
    sendBtn.current.disabled = true;
  }, []);
  const check = () => {
    const _name = name.current.value;
    const _email = email.current.value;
    const _subject = subject.current.value;
    const _message = message.current.value;

    if (_name.length == 0 || _name.split(" ").length < 1) {
        sendBtn.current.disabled = true;
      return;
    }
    if (!ValidateEmail(_email)) {
        sendBtn.current.disabled = true;
      return;
    }
    if (_subject.length == 0) {
        sendBtn.current.disabled = true;
      return;
    }
    if (_message.length == 0) {
        sendBtn.current.disabled = true;
      return;
    }
    sendBtn.current.disabled = false;
  };

  const SEND = () => {
    $.ajax({
        type: "POST",
        url: "https://willmovie.hu/api/Mailer/",
        data: {
            fullName: name.current.value,
            emailAddress: email.current.value,
            subject: subject.current.value,
            message: message.current.value      
        },
        success: function (response, state) {
          if(state == "success"){
            alert("Sikeres Küldés")
            name.current.value = "";
            email.current.value= "";
            subject.current.value= "";
            message.current.value= "";
          }
        },
      });

  };
  return (
    <>
      <div className="" id="contact">
        <div className="rootCard bg-dark">
          <h3 className="text-center text-light ">Kapcsolat</h3>
        </div>
        <div className="contact mt-5 container">
          <br />
          <br />
          <br />
          <br />
          <div className="row">
            <div className="col-md">
              <p className="fs-4">Elérhetőségek</p>
              <motion.div
              initial={{ opacity: 0, x: -300 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{
                ease: "linear",
                duration: 1,
                x: { duration: 0.5 },
              }}>
              <div className="mb-3">
                <div className="d-md-block d-none">
                </div>
                <div className="m-card " id="schere">
                  <div className="contact_item d-flex mt-5 mb-3">
                    <FaFacebook
                      style={{ marginTop: "6px" }}
                      size={30}
                      className="me-2"
                    />
                    <a
                      className="mt-2 text-black"
                      target="_blank"
                      href="https://facebook.willmovie.hu/"
                      style={{ textDecoration: "none" }}>
                      Facebook
                    </a>
                  </div>
                  <div className="contact_item d-flex">
                    <CiPhone
                      style={{ marginTop: "6px" }}
                      size={30}
                      className="me-2"
                    />
                    <p className="mt-2"> +36303698056</p>
                  </div>
                  <div className="contact_item d-flex">
                    <CiMail
                      style={{ marginTop: "6px" }}
                      size={30}
                      className="me-2"
                    />
                    <p className="mt-2">will.movie3@gmail.com</p>
                  </div>
                  <div className="contact_item d-flex mb-5">
                    <RiMapPin2Line
                      style={{ marginTop: "6px" }}
                      size={30}
                      className="me-2"
                    />
                    <p className="mt-2">
                      {" "}
                      4400, Nyíregyháza
                    
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
        
            </div>
            <div className="col-md">
              <h3 className="fs-4 mb-2">Írj üzenetet</h3>
              <hr />
              <div className="row">
                <div className="col-sm">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Teljesnév"
                      ref={name}
                      onChange={check}
                    />
                  </div>
                </div>
                <div className="col-sm">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email Cím"
                      ref={email}
                      onChange={check}
                    />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tárgy"
                  ref={subject}
                  onChange={check}
                />
              </div>
              <div className="input-group mb-3">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Üzenet"
                  style={{ borderRadius: "0" }}
                  ref={message}
                  onChange={check}></textarea>
              </div>

              <div className="input-group mb-3">
                <button
                  className="custombtn form-control btn d-flex justify-content-center"
                  style={{ borderRadius: "0" }}
                  ref={sendBtn}
                  onClick={SEND}
                  >
                  <FaEnvelope className="align-self-center me-3" size={20} />
                  Küldés
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Contact;
